<template>
  <th-modal
    width="500px"
    :title="$t('common.resource.tax.singular')"
    name="taxes"
    @close="$emit('cancel-requested')"
  >
    <el-form ref="form" v-loading="loading" :model="form" :rules="rules">
      <!-- Name -->
      <el-form-item
        :label="$t('pages.taxes.edit.form.properties.name.label')"
        prop="name"
      >
        <el-input id="name" v-model="form.name" />
      </el-form-item>

      <!-- Type -->
      <el-form-item
        :label="$t('pages.taxes.edit.form.properties.type.label')"
        prop="type"
      >
        <el-select
          id="type"
          v-model="form.type"
          :placeholder="$t('pages.taxes.edit.form.properties.type.placeholder')"
        >
          <el-option
            v-for="item in allowedTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!-- Account number -->
      <el-form-item
        :label="$t('pages.taxes.edit.form.properties.fa_account_number.label')"
        prop="fa_account_number"
      >
        <el-input
          id="fa_account_number"
          v-model="form.fa_account_number"
          :disabled="!isNew && isDatevEnabled && isDatevStrictMode"
        />
      </el-form-item>

      <!-- Rate -->
      <el-form-item
        :label="$t('pages.taxes.edit.form.properties.rate.label')"
        prop="rate"
      >
        <th-number-input
          id="rate"
          v-model="form.rate"
          percent
          :locale="$i18n.locale"
          :precision="2"
        />
      </el-form-item>

      <!-- Rate class -->
      <el-form-item
        :label="$t('pages.taxes.edit.form.properties.rate_class.label')"
        prop="rate_class"
      >
        <el-select
          id="rate_class"
          v-model="form.rate_class"
          :placeholder="$t('pages.taxes.edit.form.properties.rate_class.label')"
        >
          <el-option
            v-for="item in rateClasses"
            :key="item.value"
            :value="item.value"
            :label="item.key"
          />
        </el-select>
      </el-form-item>

      <!-- Jurisdiction -->
      <el-form-item
        :label="$t('pages.taxes.edit.form.properties.jurisdiction.label')"
        prop="jurisdiction"
      >
        <country-select
          id="jurisdiction"
          v-model="form.jurisdiction"
          data-testid="jurisdiction"
          is-eu
          iso-or-name="name"
        />
      </el-form-item>
    </el-form>

    <!-- Actions -->
    <template #footer>
      <actions
        class="pl-0 pr-0 w-full"
        :is-new="isNew"
        permission-prefix="accounting:taxes"
        @save="submitForm('form')"
        @delete="$emit('delete-requested', payload)"
      />
    </template>
  </th-modal>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import compose from 'just-compose'
import pick from 'just-pick'
import { taxClasses } from '@/constants'
import { makeHandleableBody, replaceEmptyPropsWithNull } from '@/utils/objects'
import Actions from '@/components/actions'
import CountrySelect from '@/components/country-select'

function genInitialData() {
  return {
    name: null,
    fa_account_number: null,
    rate: null,
    type: null,
    jurisdiction: null,
    rate_class: taxClasses.NORMAL
  }
}

function makeHandleableTaxBody(payload, form) {
  return compose(
    (payload) => makeHandleableBody(payload, form),
    replaceEmptyPropsWithNull
  )(payload)
}

export default {
  components: {
    Actions,
    CountrySelect
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'cancel-requested',
    'delete-requested',
    'new-item',
    'handled-item',
    'altered-item'
  ],
  data() {
    return {
      loading: false,
      valid: false,
      form: genInitialData(),
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.taxes.edit.form.rules.name.required'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        rate: [
          {
            required: true,
            message: this.$t('pages.taxes.edit.form.rules.rate.required'),
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: this.$t('pages.taxes.edit.form.rules.type.required'),
            trigger: 'blur'
          }
        ],
        rate_class: [
          {
            required: true,
            message: this.$t('pages.taxes.edit.form.rules.rate_class.required'),
            trigger: 'blur'
          }
        ],
        fa_account_number: [
          {
            required: true,
            message: this.$t(
              'pages.taxes.edit.form.rules.account_number.required'
            ),
            trigger: 'blur'
          },
          {
            validator: this.validateUniqueTaxNumberError,
            trigger: 'blur'
          }
        ]
      },
      payload: {},
      taxes: [],
      taxOptions: [],
      accounts: [],
      accountsOptions: [],
      rateClasses: [
        {
          value: taxClasses.NORMAL,
          key: this.$t(
            'pages.taxes.edit.form.properties.rate_class.types.normal'
          )
        },
        {
          value: taxClasses.REDUCED,
          key: this.$t(
            'pages.taxes.edit.form.properties.rate_class.types.reduced'
          )
        },
        {
          value: taxClasses.SUPER_REDUCED,
          key: this.$t(
            'pages.taxes.edit.form.properties.rate_class.types.super_reduced'
          )
        }
      ]
    }
  },
  computed: {
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    allowedTypes() {
      return [
        {
          value: 'vat',
          label: this.$t('pages.taxes.edit.form.properties.types.vat.label')
        }
      ]
    },
    isDatevEnabled() {
      return this.$isFeatureEnabled('datev')
    },
    isDatevStrictMode() {
      const strictMode = safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        ['datev', 'strict_mode']
      )
      return typeof strictMode === 'boolean' ? strictMode : true
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetForm()
      if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.$route.params.id)
    },
    'form.fa_account_number': function () {
      if (this.taxNumberError) this.taxNumberError = false
    }
  },
  async mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        const inst = th.taxes()

        this.loading = true

        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.tax.singular')
          })
        })
      }
    },
    handleItem(item) {
      this.payload = item
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
    },
    submitForm(formName) {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })
        }
        // We need this line because country select sends with uppercase and the schema accepts only lowercase
        if (this.form.jurisdiction)
          this.form.jurisdiction = this.form.jurisdiction.toLowerCase()
        if (this.isNew) return this.create()
        this.alter(this.payload.id)
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetForm(formName) {
      if (!this.$refs[formName]) return
      this.$refs[formName].resetFields()
    },
    resetData(formName) {
      this.form = genInitialData()
    },
    async alter(id) {
      const payload = {
        ...this.payload,
        ...this.form
      }
      const shouldWarnOnDatevChange =
        !this.isNew &&
        this.isDatevEnabled &&
        this.isDatevStrictMode === false &&
        this.payload.fa_account_number !== this.form.fa_account_number

      if (shouldWarnOnDatevChange) {
        const objectName = this.$t(
          'pages.taxes.edit.form.properties.fa_account_number.label'
        )
        try {
          await this.$confirm(
            this.$t('common.datev.related_change.warning.message', {
              objectName
            }),
            this.$t('common.titles.warning'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.ok'),
              cancelButtonText: this.$t('common.interactions.buttons.cancel'),
              type: 'warning'
            }
          )
        } catch (err) {
          // no-op
          return
        }
      }

      try {
        const inst = th.taxes()
        this.loading = true

        const { data = {} } = await inst.put(
          payload.id,
          makeHandleableTaxBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.tax.singular')
            })
          })
        }

        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.checkUniqueTaxNumberError(err)
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.tax.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    async create() {
      const payload = {
        ...this.form
      }

      try {
        const inst = th.taxes()
        this.loading = true

        // we will handle currency and timezone as configuration, TODO: implement
        const { data = {} } = await inst.create(
          makeHandleableTaxBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.tax.singular')
            })
          })
        }

        this.$emit('handled-item')
        this.$emit('new-item')
      } catch (err) {
        this.checkUniqueTaxNumberError(err)
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.tax.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    checkUniqueTaxNumberError(err) {
      const errorMsg = safeGet(err, 'properties.error.response.data.msg') || ''
      // Tax account number \"1771\" already exists
      if (errorMsg.includes('already exists')) {
        this.taxNumberError = true
        this.$refs.form.validate()
      }
    },
    validateUniqueTaxNumberError(rule, value, callback) {
      if (this.taxNumberError) {
        callback(
          new Error(
            this.$t('pages.taxes.edit.form.rules.fa_account_number.unique', {
              fa_account_number: this.form.fa_account_number
            })
          )
        )
      } else {
        callback()
      }
    }
  }
}
</script>
